@import url("https://fonts.googleapis.com/css?family=Open+Sans");

* {
  transition: all 0.6s cubic-bezier(0.2, 0, 0.2, 1);
}

html,
body,
main {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: "Hack";
}

main {
  display: grid;
  grid-template-rows: 100px auto;
  grid-template-columns: 50px 1fr 1fr 1fr 50px;

  color: white;

  overflow: hidden;

  .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;

    grid-column: 2;
  }

  .car-text {
    grid-row: 2;
    grid-column: 2 / 4;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 3rem;

    ul {
      padding: 0;
      line-height: 0.9;
      list-style-type: none;

      z-index: 1;

      li {
        label {
          font-size: 5.5rem;
          font-weight: bold;
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
          -webkit-text-stroke: 1px rgba(255, 255, 255, 0.2);
        }

        input {
          display: none;

          &:checked ~ label {
            -webkit-text-fill-color: white;
          }
        }
      }
    }
  }

  .car-image {
    grid-row: 1;
    grid-column: 3 / 5;

    canvas {
      transition: all 4s linear;
    }

    .canvas {
      width: 100%;
      height: 60%;

      background-image: url("https://electrek.co/wp-content/uploads/sites/3/2016/06/home_hero_models-e1465956017150.jpg?quality=82&strip=all&w=1600");
      background-position: center;
    }
  }
}

#title-text {
  canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
:root {
  --grid-color: #35a4f7;
  --title-font: "Hack", cursive;
  --subtitle-font: "", cursive;
}

body {
  background: radial-gradient(
    ellipse at center,
    #0b1f59 0%,
    #0f3c77 27%,
    #8b008b 95%
  );
}
section.synth {
  margin: 0;
  background: radial-gradient(
    ellipse at center,
    #0b1f59 0%,
    #0f3c77 27%,
    #8b008b 95%
  );
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.cover {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #000;
  z-index: 2;
}

.container {
  width: 100vw;
  position: absolute;
  bottom: 0;
  left: 0;
  transform-style: preserve-3d;
  perspective-origin: bottom;
  backface-visibility: visible;
  display: none;
}

#sun {
  height: 200px;
  width: 200px;
  background: linear-gradient(to bottom, magenta 0%, yellow 100%);
  border-radius: 50%;
  position: absolute;
  box-shadow: 0 0 300px 50px orangered;
  transform: translate(0, 50px);
  animation: dawn 3s ease-out 1 forwards;
}

#horizon {
  width: 104%;
  height: 378px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  z-index: 1;
  border-bottom: solid 2px var(--grid-color);
  box-shadow: inset 0 0 15px 2px var(--grid-color);
  transform: translate(-2%, -10px);
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

#horizon > .inner {
  position: relative;
  transform: translate(0, 102px);
  animation: zoom-in 3s ease-out 1 forwards;
}

#horizon > .inner > .title {
  font-size: 160px;
  font-family: "Hack";
  font-weight: normal;
  color: white;
  filter: drop-shadow(0 0 5px white);
  margin: 0;
  padding: 0;
  position: relative;
  -webkit-text-stroke: 2px #ddd;
}

#horizon > .inner > .subtitle {
  font-size: 63px;
  font-family: "Hack";
  color: #fe05cb;
  font-weight: normal;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  bottom: -48px;
  right: -25px;
  transform: rotate(-3deg);
  filter: drop-shadow(4px 3px 0px #0f3c77);
  animation: pulse 1.25s linear infinite forwards;
}

$speed: 5s;
$increase-vert: $speed/10;
$scanlines: 1px;

@keyframes blur-n-hue {
  0% {
    filter: blur(10px) hue-rotate(-15deg);
  }
  50% {
    filter: blur(20px) hue-rotate(0deg);
  }
  100% {
    filter: blur(10px) hue-rotate(-15deg);
  }
}
@keyframes move {
  from {
    top: 0%;
    opacity: 0.8;
  }
  to {
    top: 100%;
    opacity: 1;
  }
}
@keyframes angle {
  0% {
    transform: rotateX(58deg);
  }
  50% {
    transform: rotateX(60deg);
  }
  100% {
    transform: rotateX(58deg);
  }
}
@keyframes bg {
  0% {
    background-color: rgba(46, 46, 46, 0);
  }
  49% {
    background-color: rgba(46, 46, 46, 0);
  }
  50.5% {
    background-color: rgba(155, 155, 206, 0.4);
  }
  51% {
    background-color: rgba(46, 46, 46, 0);
  }
  51.5% {
    background-color: rgba(155, 155, 206, 0.3);
  }
  57% {
    background-color: rgba(46, 46, 46, 0);
  }
  100% {
    background-color: rgba(46, 46, 46, 0);
  }
}
html {
  background: black;
}
.synth {
  background: rgba(46, 46, 46, 1);
  animation: bg 10s infinite ease-out;
  height: 100vh;
  overflow: hidden;
  position: relative;
  margin: 0;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(30, 30, 30, 0.2); //fallback
    background: -webkit-repeating-linear-gradient(
      top,
      transparent 0px,
      transparent $scanlines,
      rgba(30, 30, 30, 0.15) $scanlines,
      rgba(30, 30, 30, 0.15) $scanlines * 2
    );
    -webkit-background-size: 100% $scanlines * 2;
    background: -moz-repeating-linear-gradient(
      top,
      transparent 0px,
      transparent $scanlines,
      rgba(30, 30, 30, 0.15) $scanlines,
      rgba(30, 30, 30, 0.15) $scanlines * 2
    );
    -moz-background-size: 100% $scanlines * 2;
    display: block;
    z-index: 10;
  }
  // &:after {
  // 	animation: bg 10s infinite ease-out;
  // 	content: "";
  // 	position: absolute;
  // 	top: 0;
  // 	left: 0;
  // 	width: 100%;
  // 	height: 100%;
  // 	display: block;
  // 	z-index: 20;
  // }
}
.text {
  color: white;
  font: small-caps lighter 14px/150% "Helvetica Neue", Helvetica, sans-serif;
  letter-spacing: 0.2rem;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  text-align: left;
  line-height: 1.2em;
  filter: colour-shift(10);
  z-index: 20;
  b {
    font-size: 2.5em;
    &:after {
      content: "v2";
      font-weight: 100;
      font-size: 12px;
      position: absolute;
    }
  }
}

section.animation-some {
  animation: blur-n-hue $speed infinite ease-in-out;
  content: "";
  display: block;
  background: rgba(224, 87, 90, 1);
  background: linear-gradient(
    180deg,
    rgba(150, 50, 50, 0.8) 0%,
    rgba(200, 50, 100, 1) 6%,
    rgba(220, 220, 220, 1) 8%,
    rgba(224, 87, 90, 1) 12%,
    rgba(224, 37, 90, 1) 100%
  );
  width: 150vw;
  height: 80%;
  left: -25%;
  bottom: -10%;
  position: absolute;
}

.grid {
  perspective: 300px;
  width: 100%;
  height: 100%;
  position: relative;
  .grid-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: rotateX(60deg);
    //animation: angle 5s infinite ease-in-out;
  }
}
.line {
  display: block;
  position: absolute;
  width: 0px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid white;
  box-sizing: border-box;
  height: 100%;
  background: white;
  box-shadow: 0px 0px 6px 2px rgba(255, 225, 225, 0.8);
}
.hori,
.vert {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
}
.hori {
  .line {
    &:nth-child(1) {
      left: -40%;
    }
    &:nth-child(2) {
      left: -20%;
    }
    &:nth-child(3) {
      left: 0%;
    }
    &:nth-child(4) {
      left: 20%;
    }
    &:nth-child(5) {
      left: 40%;
    }
    &:nth-child(6) {
      left: 60%;
    }
    &:nth-child(7) {
      left: 80%;
    }
    &:nth-child(8) {
      left: 100%;
    }
    &:nth-child(9) {
      left: 120%;
    }
    &:nth-child(10) {
      left: 140%;
    }
  }
}

.vert {
  .line {
    animation: move $speed infinite linear;
    width: 200%;
    left: -50%;
    height: 0px;
    &:nth-child(1) {
      animation: none;
      opacity: 0.7;
    }
    &:nth-child(2) {
      animation-delay: $increase-vert;
    }
    &:nth-child(3) {
      animation-delay: $increase-vert * 2;
    }
    &:nth-child(4) {
      animation-delay: $increase-vert * 3;
    }
    &:nth-child(5) {
      animation-delay: $increase-vert * 4;
    }
    &:nth-child(6) {
      animation-delay: $increase-vert * 5;
    }
    &:nth-child(7) {
      animation-delay: $increase-vert * 6;
    }
    &:nth-child(8) {
      animation-delay: $increase-vert * 7;
    }
    &:nth-child(9) {
      animation-delay: $increase-vert * 8;
    }
    &:nth-child(10) {
      animation-delay: $increase-vert * 9;
    }
  }
}

@keyframes sail {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(665px);
  }
}

@keyframes dawn {
  0% {
    transform: translate(0, -400px);
  }
  100% {
    transform: translate(0, -110px);
  }
}

@keyframes zoom-in {
  0% {
    transform: translate(0, 72px) scale(0.5);
  }
  100% {
    transform: translate(0, 102px) scale(1);
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes pulse {
  0% {
    transform: rotate(-3deg) scale(1);
  }
  89% {
    transform: rotate(-3deg) scale(1);
  }
  90% {
    transform: rotate(-3deg) scale(1.1);
  }
  100% {
    transform: rotate(-3deg) scale(1);
  }
}

@keyframes hue-rotate {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}
